.loading {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  z-index: 2000;

  .spinner {
    position: absolute;
    z-index: 2005;
  }

  .icon {
    z-index: 2005;
  }

  .back-drop {
    background-color: #000000;
    height: 100%;
    width: 100%;
    position: absolute;
    opacity: 0.25;
  }

  &.fade-in {
    animation: fade-in .5s linear;
  }

  &.fade-out {
    animation: fade-out .5s linear;
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes fade-out {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
}