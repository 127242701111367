.pagination {
  .max-row {
    .select {
      width: 100px !important;
    }
  }

  button {
    width: 42px;
  }

  select {
    height: 38px;
    width: 70px;
  }
}