.btn-primary {
  --#{$prefix}btn-color: #{$white};
  --#{$prefix}btn-hover-color: #{$white};
  --#{$prefix}btn-active-color: #{$white};
}

.btn-light {
  --#{$prefix}btn-color: #{$gray-800};
  --#{$prefix}btn-bg: #{$gray-100};
  --#{$prefix}btn-border-color: #{$gray-800};
}

.btn-link {
  --#{$prefix}btn-color: #{$link};
  --#{$prefix}btn-hover-color: #{$link-50};
  --#{$prefix}btn-active-color: #{$link-50};

  &:hover,
  &:focus-visible,
  &:active {
    ---#{$prefix}btn-active-color:#{$link-50};
    ---#{$prefix}btn-active-bg: transparent;
    background-color: transparent !important;
  }
}

.btn-outline-primary {
  --#{$prefix}btn-hover-color: #{$white};
  --#{$prefix}btn-active-color: #{$white};
}

.btn-success {
  --#{$prefix}btn-color: #{$white};
  --#{$prefix}btn-hover-color: #{$white};
  --#{$prefix}btn-active-color: #{$white};
}