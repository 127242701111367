.date-picker {
  .calendar {
    margin: .75rem;
    display: flex;
    gap: 0.5rem;
    flex-direction: column;

    .year-month-select {
      display: flex;
      gap: 0.5rem;
    }

    .day-name {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;

      p {
        margin-bottom: 0;
        width: 30.46px;
        height: 30.46px;
        border-radius: .25rem;
        background-color: $calendar-color;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .date {
      display: flex;
      gap: 0.5rem;
      flex-direction: column;

      .row-date {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;

        p {
          margin-bottom: 0;
          width: 30.46px;
          height: 30.47px;
          border-radius: .25rem;
          display: flex;
          align-items: center;
          justify-content: center;

          &.date {
            cursor: pointer;
            border: 1px solid $calendar-color;

            &.active {
              border: 1px solid $primary;
              background-color: $primary;
              color: $white;
            }
          }
        }
      }
    }

    select {
      background-color: $white;
      border-color: $calendar-color;
      color: $black;
    }

    .month {
      display: flex;
      gap: 0.5rem;
      flex-direction: column;

      .row-mount {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;

        p {
          margin-bottom: 0;
          width: 95px;
          height: 30.47px;
          border-radius: .25rem;
          display: flex;
          align-items: center;
          justify-content: center;

          &.month {
            cursor: pointer;
            border: 1px solid $calendar-color;

            &.active {
              border: 1px solid $primary;
              background-color: $primary;
              color: $white;
            }
          }
        }
      }
    }

    button {
      height: 30px;
      font-size: 14px;
      padding: 0;
    }

    .year-select {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      align-items: center;
    }
  }

  &.disabled {
    svg {
      color: #cccccc;
    }
  }
}