$primary: #6A0F0F;
$primary-dark: #4B0F0F;
$primary-light: #942A2A;
$danger: #D92D20;
$light: #FBFBFB;
$light-border: #D0D5DD;
$white: #FFFFFF;
$black: #000000;
$table-striped-bg: #FCF4F4;
$nav-pills-link-active-bg: transparent;
$nav-pills-link-active-color: $primary;
$nav-pills-border-radius: 0;
$calendar: #e9ecef;
$icon-input-color: #667085;
$offcanvas-bg-color: $primary;
$offcanvas-backdrop-opacity: .25;
$offcanvas-horizontal-width: 280px;
$success: #517634;
$pending: #9B9B9B;
$warning: #ffc107;
$calendar-color: #e9ecef;
$link : #176FD3;
$link-50 : lighten($link, 10%);
$font-size-default: 1rem;
$title-font-size: 1.45rem;
$success-chapter: #039855;
$pending-chapter: #F04438;

$theme-colors: (
  "primary": $primary,
  "primary-light": $primary-light,
  "danger": $danger,
  "light": $light,
  "light-border":$light-border,
  "success": $success,
  "pending": $pending,
  "warning": $warning,
  "success-chapter": $success-chapter,
  "pending-chapter": $pending-chapter,
);