.status {
  .badge {
    color: $white;
    font-size: 0.95rem;

    &.draft {
      background-color: #BABABA !important;
      color: $white
    }

    &.confirm {
      background-color: #3B63F0 !important;
      color: $white;
    }

    &.cancel {
      background-color: #F04438 !important;
      color: $white;
    }

    &.record {
      background-color: #FFC806 !important;
      color: $white;
    }

    &.recorded {
      background-color: #23CECE !important;
      color: $white;
    }

    &.documentMaking {
      background-color: #FC8518 !important;
      color: $white;
    }

    &.documentComplete {
      background-color: #2C3BC6 !important;
      color: $white;
    }

    &.overviewReviewing {
      background-color: #CE5613 !important;
      color: $white;
    }

    &.overviewReviewed {
      background-color: #00812C !important;
      color: $white;
    }

    &.guarantee {
      background-color: #6A3BF0 !important;
      color: $white;
    }

    &.reject {
      background-color: #DD1305 !important;
      color: $white;
    }

    &.assignDutyOfficer {
      background-color: #C4C4C4 !important;
      color: $white;
    }

    &.confirm-chapter {
      background-color: #797979 !important;
      color: $white;
    }

    &.waitRecord {
      background-color: #FFC806 !important;
      color: $white;
    }

    &.makeDocument {
      background-color: #FC8518 !important;
      color: $white;
    }

    &.makeDoneDocument {
      background-color: #3B63F0 !important;
      color: $white;
    }

    &.reviewedDocument {
      background-color: #FF5C00 !important;
      color: $white;
    }

    &.sendEditDocument {
      background-color: #F04438 !important;
      color: $white;
    }

    &.collect {
      background-color: #4AB4CB !important;
      color: $white;
    }

    &.comfirm-assign {
      background-color: #008A2F !important;
      color: $white;
    }
  }
}