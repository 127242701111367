.form-control {
  font-size: $font-size-default;

  &.is-invalid {
    background-image: none;
    z-index: auto !important;
  }
}

.calendar {
  width: 18rem;

  .day-name {
    display: flex;
    flex-direction: row;

    p {
      margin-bottom: 0;
      width: 30.46px;
      height: 30.46px;
      margin-left: 0.15rem;
      margin-right: 0.15rem;
      border-radius: .25rem;
      background-color: $calendar;
      display: flex;
      align-items: center;
      justify-content: center;

      &:first-child {
        margin-left: 0;
        margin-right: 0.15rem;
      }

      &:last-child {
        margin-left: 0.15rem;
        margin-right: 0;
      }
    }
  }

  .row-date {
    display: flex;
    flex-direction: row;

    p {
      margin-bottom: 0;
      width: 30.46px;
      height: 30.47px;
      margin-left: 0.15rem;
      margin-right: 0.15rem;
      border-radius: .25rem;
      display: flex;
      align-items: center;
      justify-content: center;

      &:first-child {
        margin-left: 0;
        margin-right: 0.15rem;
      }

      &:last-child {
        margin-left: 0.15rem;
        margin-right: 0;
      }

      &.date {
        cursor: pointer;
        border: 1px solid $calendar;

        &.active {
          border: 1px solid $primary;
          background-color: $primary;
          color: $white;
        }
      }
    }
  }
}

.input-group-text {
  svg {
    color: $icon-input-color;
  }
}