.sign-in {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $primary;

  .content {
    width: 35rem;
    border-radius: 1rem;
    background-color: $white;
  }

  .version {
    position: absolute;
    color: white;
    bottom: 0;
    right: 0;
  }

  .user-manual {
    position: absolute;
    color: white;
    bottom: 0;
    left: 0;
  }
}