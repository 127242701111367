.select {
  div[class$='-control'] {
    border: 1px solid #E4E4E4;
  }

  .is-invalid {
    div[class$='-control'] {
      border-color: #dc3545;
    }
  }

  .disabled {
    div[class$='-control'] {
      div[class$='-singleValue'] {
        color: #000;
      }
    }
  }
}