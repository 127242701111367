.table {
  thead {
    background-color: #D4B6B6;
  }

  th:first-child {
    border-radius: .75rem 0 0 0;
  }

  th:last-child {
    border-radius: 0 .75rem 0 0;
  }

  tr:last-child td:first-child {
    border-radius: 0 0 0 .75rem;
  }

  tr:last-child td:last-child {
    border-radius: 0 0 .75rem 0;
  }

  th {
    text-align: center;
    vertical-align: middle;
  }

  tr {
    &.active {
      td {
        --bs-table-accent-bg: unset;
        background-color: $primary;
        color: $white;

        svg {
          color: $white;
        }
      }
    }

    td {
      &>svg {
        color: $primary;
        cursor: pointer
      }
    }

    .summary-row {
      height: 48.39px;
    }

    .border-black {
      border-color: black !important;
    }

    .text-center-position {
      position: relative;
      top: -20px;
    }
  }
}

.table-no-rounded {
  th:first-child {
    border-radius: 0 0 0 0;
  }

  th:last-child {
    border-radius: 0 0 0 0;
  }
}

.rows-selected {
  background-color: #FFDCDC !important;
}

.table-relative-fix {
  position: relative;

  .table-scroll {
    overflow-y: visible;
    padding-bottom: 5px;
    width: 100%;
  }

  th.fix-col {
    right: 0;
    position: sticky;
    top: auto;
    background-color: #D4B6B6;
  }

  tr {
    height: 60px;

    td.fix-col {
      padding: 0;
      background-color: white;
      width: 100px;
      position: sticky;
      right: 0;
    }
  }
}