.sidebar {
  width: 280px;
  height: calc(100vh - 86px);
  background-color: $primary;
  overflow-y: auto;
  top: 86px;
  position: fixed;
  z-index: 10;
  transition: transform 0.3s ease-in-out;
  transform: translateX(0);

  &.collapsed {
    transform: translateX(-280px);
  }

  .menu {

    .parent-first,
    .parent-second,
    .child {
      color: $white;
      cursor: pointer;
    }

    .parent-first {
      background-color: $primary-dark;
      padding: .5rem 1.25rem .5rem 1.25rem;
    }

    .parent-second {
      background-color: #4b0f0fb3;
      padding: .5rem 1.25rem .5rem 2.25rem;
    }

    .child {
      padding: .25rem .5rem .25rem 5rem;

      .label {
        padding: .25rem 1rem .25rem;
        border-radius: 0.25rem;
        line-height: 1.25;
      }

      &.active {
        .label {
          background-color: $primary-light;
        }
      }
    }
  }

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #eeeeee;
  }

  &::-webkit-scrollbar-thumb {
    background: $primary-dark;
  }
}

.btn-collapse {
  height: 5rem;
  width: 1.5rem;
  background-color: $primary;
  position: fixed;
  top: calc(50% - 86px);
  left: 280px;
  border-radius: 0 .5rem .5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
  font-size: 1rem;
  cursor: pointer;
  z-index: 10;
  transition: transform 0.3s ease-in-out;
  transform: translateX(0);

  &.collapsed {
    transform: translateX(-280px);
  }
}

.btn-collapse.collapsed + div.layout {
  left: 0;
  width: 100%;
}