nav.navbar {
  position: fixed;
  width: 100%;
  z-index: 100;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 1);

  .navbar-brand,
  .navbar-text {
    color: $white;
  }

  .navbar-toggler{
    &:focus{
      --bs-navbar-toggler-focus-width: 0;
    }
  &-icon {
    background-image: none;
    position: relative;
    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 11px;
      border-bottom: 2px solid $white;
      border-top: 2px solid $white;
      margin-top: 4px;
    }
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 2px;
      background-color:$white;
      margin-top: 0.465rem;
    }
  }
}

  .user-info {
    padding-left: 1rem;
    padding-right: 1rem;
    cursor: pointer;
    display: flex;
    gap: 1rem;

    .img {
      img {
        width: 50px;
        border-radius: 50%;
      }
    }
  }

  .module {
    border-left: 1px solid $white;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .sign-out {
    border-left: 1px solid $white;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    font-size: 1.25rem;
    cursor: pointer;
  }
}