.layout {
  position: absolute;
  top: 86px;
  left: 280px;
  width: calc(100% - 280px);
  padding: 2rem 2rem calc(2rem + 54px) 2rem;
  transition: 0.3s ease-in-out;

  @media (max-width: 991px) {
    left: 0;
    width: 100%;
  }

  .title {
    font-size: $title-font-size;
  }

  .action-button {
    margin-bottom: 1rem;
  }
}