.footer {
  position: fixed;
  background-color: $black;
  width: calc(100% - 280px);
  color: $white;
  bottom: 0;
  left: 280px;
  padding: 0.75rem 1.25rem 0.75rem 1.25rem;
  font-size: 1.25rem;
  transition: 0.3s ease-in-out;

  &.collapsed {
    width: 100%;
    left: 0;
  }
}