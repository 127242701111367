.s0202-detail {
  .time {
    display: flex;
    justify-content: center;

    @include media-breakpoint-down(lg) {
      font-size: 60px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 80px;
    }
  }
  
  .time-card {
    @include media-breakpoint-down(lg) {
      margin-top: 14px;
    }
  }

  .video {
    width: 100%;
    background-color: $black;

    @include media-breakpoint-down(md) {
      height: 500px;
    }

    @include media-breakpoint-up(md) {
      height: 100%;
    }
  }

  .controller {
    button {
      width: 100%;
      height: 100%;
      font-size: 32px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}